import io from 'socket.io-client';
import axiosInstance from '../../../utils/axios';
import { setCardProperty, setCheckoutProperty, setProduct } from './products.slice';

const user = JSON.parse(localStorage.getItem('user'));
const socket = io(process.env.REACT_APP_SOCKET);

export const getCustomerAddress = (id) => async (dispatch, getState) => {
  try {
    const { userAddress } = getState().products;
    if (!userAddress) {
      const res = await axiosInstance.post('/customers/user', { id: user?._id ? user._id : id });
      dispatch(setProduct({ option: 'userAddress', value: res.data }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllProducts =
  (isRefresh = false) =>
  async (dispatch, getState) => {
    const { products } = getState().products;
    console.log(products);
    try {
      dispatch(setProduct({ option: 'isLoading', value: true }));

      if (products.length === 0 && !isRefresh) {
        console.log('entro');
        const res = await axiosInstance.get('/product/getproducts');
        dispatch(setProduct({ option: 'products', value: res.data }));
      }

      dispatch(setProduct({ option: 'isLoading', value: false }));
    } catch (error) {
      console.log(error);
    }
  };

export const getProduct = (productId) => async (dispatch, getState) => {
  try {
    const {
      products: { productDescription },
    } = getState();
    if (productDescription && productDescription._id === productId) return;
    const response = await axiosInstance.get(`/product/${productId}`);
    dispatch(setProduct({ option: 'productDescription', value: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getAllBrands = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get('/brands/all');
    dispatch(setProduct({ option: 'brands', value: res.data }));
  } catch (error) {
    console.log(error);
  }
};

export const getAllCategories = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get('/show/category');
    dispatch(setProduct({ option: 'category', value: res.data.showAll }));
    console.log(res.data.showAll);
  } catch (error) {
    console.log(error);
  }
};

export const getAllSubCategories = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get('/show/allsub');
    dispatch(setProduct({ option: 'subCategories', value: res.data.showAll }));
  } catch (error) {
    console.log(error);
  }
};

export const createProduct = (product, options, setDisplayValue) => async (dispatch) => {
  try {
    dispatch(setProduct({ option: 'isCreateProduct', value: true }));
    const res = await axiosInstance.post('/product/upload', product);

    options.enqueueSnackbar(
      !options.isEdit ? 'Producto Creado Correctamente' : 'Producto Editado Correctamente'
    );

    dispatch(setProduct({ option: 'products', value: [] }));

    options.reset(options.defaultValues, { shouldValidate: false });
    setDisplayValue({ price: '', priceSale: '' });
    dispatch(setProduct({ option: 'isCreateProduct', value: false }));

    return product;
  } catch (error) {
    return console.log(error);
  }
};

export const updateProduct =
  (id, product, options, setDisplayValue) => async (dispatch, getState) => {
    const { enqueueSnackbar, navigate } = options;

    dispatch(setProduct({ option: 'isCreateProduct', value: true }));
    // Obtener el producto original de Redux usando el id
    const originalProduct = getState().products.products.find((prod) => prod._id === id);

    // Identificar las URLs de imágenes que han sido eliminadas
    const deletedImages = originalProduct.img.filter(
      (url) => typeof url === 'string' && !product.img.includes(url)
    );

    // Clonamos el objeto del producto para no modificar el original
    const updatedProduct = { ...product };

    // Pasamos el array de imagenes eliminadas
    updatedProduct.deletedImages = deletedImages;

    // Si img existe y contiene archivos File, los conservamos, de lo contrario eliminamos la propiedad
    if (updatedProduct.img && updatedProduct.img.some((image) => image instanceof File)) {
      updatedProduct.img = updatedProduct.img.filter((image) => image instanceof File);
    } else {
      delete updatedProduct.img;
    }

    try {
      const formData = new FormData();
      Object.entries(updatedProduct).forEach(([key, value]) => {
        if (key === 'img') {
          value.forEach((file, index) => {
            formData.append(`img`, file);
          });
        } else if (key === 'deletedImages') {
          value.forEach((url, index) => {
            formData.append(`deletedImages[${index}]`, url);
          });
        } else {
          formData.append(key, value);
        }
      });

      const res = await axiosInstance.put(`/product/${id}`, formData);
      dispatch(setProduct({ option: 'isCreateProduct', value: false }));

      options.enqueueSnackbar(
        !options.isEdit ? 'Producto Creado Correctamente' : 'Producto Editado Correctamente'
      );
      dispatch(setProduct({ option: 'products', value: [] }));
      navigate('/dashboard/e-commerce/list');
    } catch (error) {
      console.log(error);
      dispatch(setProduct({ option: 'isCreateProduct', value: false }));
    }
  };

export const getAllCartProduct = (id) => async (dispatch) => {
  try {
    dispatch(getCustomerAddress(id));
    socket.emit('get_cart_user', user?._id ? user._id : id);
    socket.on(`cart_user_${user?._id ? user._id : id}`, (data) => {
      dispatch(setProduct({ option: 'allCartProducts', value: data.cart }));
      dispatch(setProduct({ option: 'allCartProductStock', value: data.adjustedProducts }));
    });
  } catch (error) {
    console.log(error);
  }
};

export const addToCartProduct = (productId, amount) => async (dispatch) => {
  console.log(user._id);
  console.log(productId);
  console.log(amount);

  try {
    socket.emit(`add_cart`, { userId: user._id, productId, amount });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCartProduct = (productId) => async (dispatch) => {
  try {
    socket.emit(`delete_item_cart`, { userId: user._id, productId });
  } catch (error) {
    console.log(error);
  }
};

export const UpdateaddToCartProduct = (productId, amount) => async (dispatch) => {
  try {
    socket.emit(`update_cart`, { productId, amount, query: 'add', userId: user._id });
  } catch (error) {
    console.log('error');
  }
};

export const UpdateExtraAddtoCartProduct = (productId, amount) => async (dispatch) => {
  socket.emit(`update_cart`, { productId, amount, query: 'extra', userId: user._id });
};

export const UpdatedeleteCartProduct = (productId, amount) => async (dispatch) => {
  try {
    socket.emit(`update_cart`, { productId, amount, query: 'del', userId: user._id });
  } catch (error) {
    console.log(error);
  }
};

export const ApplyDiscount = () => async (dispatch) => {
  try {
    const fakeDiscount = {
      code: 'FAKE 40%',
      discountPercent: 40,
    };
    dispatch(setProduct({ option: 'promotionCode', value: fakeDiscount }));
  } catch (error) {
    console.log(error);
  }
};

export const checkoutUpdater = (property, value) => async (dispatch) => {
  dispatch(setCheckoutProperty({ option: property, value }));
}

export const checkoutCard = (property, value) => async (dispatch) => {
  dispatch(setCardProperty({option: property, value}))
}
export const onlyBrand = (brand) => async (dispatch) => {
  dispatch(setProduct({ option: 'onlyBrand', value: brand }));
};
