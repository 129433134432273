import PropTypes from 'prop-types';
import { useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Link, Stack } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
import { PATH_AUTH, PATH_DOCS, PATH_MINIMAL_ON_STORE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import Label from '../../components/label';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import LogoFull from '../../assets/logo/LogoFull';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  console.log(isOffset);

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0, backgroundColor: '#00092c' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default, opacity: 1 }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Box display="flex" alignItems="center" sx={{ height: '100%', width: { xs: '20%' } }}>
            <LogoFull colorFont={isOffset ? '#00092c' : undefined} />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}

          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: 'white',
                transition: 'all 0.2s ease-in-out',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: 'none',
                  transform: 'scale(1.05)',
                },
              }}
              variant="contained"
              rel="noopener"
              href={PATH_AUTH.login}
            >
              Ingresa
            </Button>

            <Button
              sx={{
                backgroundColor: '#F85C07',
                transition: 'all 0.2s ease-in-out',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#F85C07',
                  boxShadow: 'none',
                  transform: 'scale(1.05)',
                },
              }}
              variant="contained"
              rel="noopener"
              href={PATH_AUTH.register}
            >
              Registrate
            </Button>
          </Stack>

          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
