import PropTypes from 'prop-types';
import { useRef } from 'react';

// @mui
import { Box, Button, Typography, Stack } from '@mui/material';
// components
import { SkeletonProductItem } from '../../../../components/skeleton';
//
import ShopProductCard from './ShopProductCard';
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

function groupBySubcategory(products) {
  return products.reduce((acc, product) => {
    const subcategoryName = product.subcategory.name;
    if (acc[subcategoryName]) {
      acc[subcategoryName].push(product);
    } else {
      acc[subcategoryName] = [product];
    }
    return acc;
  }, {});
}

const smoothScrollTo = (element, target, duration) => {
  const start = element.scrollLeft;
  const change = target - start;
  const startTime = performance.now();
  const endTime = startTime + duration;

  const animateScroll = (currentTime) => {
    const time = Math.min(1, (currentTime - startTime) / duration);
    const timeFunction = time * (2 - time); // Función de facilidad cuadrática
    element.scrollLeft = start + change * timeFunction;

    if (time < 1) requestAnimationFrame(animateScroll);
  };

  requestAnimationFrame(animateScroll);
};

ShopProductList.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.array,
  open: PropTypes.func,
  groupByCategory: PropTypes.bool,
};

export default function ShopProductList({
  products,
  loading,
  open,
  groupByCategory = true,
  ...other
}) {
  const groupedProducts = groupBySubcategory(products);

  // Referencia al contenedor de productos para manipular su desplazamiento
  const scrollRefs = useRef({});

  // Funciones para desplazar el contenido
  const scrollLeft = (subcategoryName) => {
    if (scrollRefs.current[subcategoryName]) {
      const currentScrollPosition = scrollRefs.current[subcategoryName].scrollLeft;
      smoothScrollTo(scrollRefs.current[subcategoryName], currentScrollPosition - 300, 400);
    }
  };

  const scrollRight = (subcategoryName) => {
    if (scrollRefs.current[subcategoryName]) {
      const currentScrollPosition = scrollRefs.current[subcategoryName].scrollLeft;
      smoothScrollTo(scrollRefs.current[subcategoryName], currentScrollPosition + 300, 400);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px" {...other}>
      {groupByCategory ? (
        Object.keys(groupedProducts).map((subcategoryName) => (
          <Box key={subcategoryName} marginBottom={3}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{subcategoryName}</Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  sx={{ backgroundColor: '#FEF2EC', ':hover': { backgroundColor: '#ffd2bb' } }}
                  onClick={() => scrollLeft(subcategoryName)}
                >
                  <Iconify icon="iconamoon:arrow-right-2-bold" rotate={2} />{' '}
                </Button>
                <Button
                  sx={{ backgroundColor: '#FEF2EC', ':hover': { backgroundColor: '#ffd2bb' } }}
                  onClick={() => scrollRight(subcategoryName)}
                >
                  <Iconify icon="iconamoon:arrow-right-2-bold" />{' '}
                </Button>
              </Stack>
            </Box>
            <Box
              ref={(el) => {
                scrollRefs.current[subcategoryName] = el;
              }}
              width="100%"
              sx={{
                overflowX: 'auto',
                transition: 'scrollLeft 0.4s ease', // Aquí añadimos la transición
                '&::-webkit-scrollbar': {
                  width: '2px', // Ancho de la barra de desplazamiento
                  height: '2px', // Altura de la barra de desplazamiento (en caso de que se desplace verticalmente)
                  borderRadius: '2px', // Bordes redondeados
                  background: 'transparent', // Color de fondo
                  display: 'none', // Ocultar barra de desplazamiento
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888', // Color del deslizador de la barra de desplazamiento
                  borderRadius: '2px', // Bordes redondeados del deslizador
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555', // Color del deslizador al pasar el mouse
                },
                scrollbarWidth: 'thin', // Para Firefox
                scrollbarColor: '#888 #E1E1E1', // Primero es el color del pulgar y luego el track para Firefox
              }}
            >
              <Box
                gap={1}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(3, 1fr)',
                  md: 'repeat(4, 1fr)',
                  lg: 'repeat(6, 1fr)',
                }}
                maxWidth="100%"
                gridTemplateRows="repeat(2, 1fr)"
                gridAutoFlow="column"
                padding="15px"
              >
                {groupedProducts[subcategoryName].map((product) => (
                  <ShopProductCard key={product._id} product={product} open={open} />
                ))}
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box
          gap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(2, 1fr)', // En móvil siempre 2 columnas
            sm: 'repeat(auto-fill, minmax(180px, 1fr))', // A partir de 'sm', las columnas tendrán al menos 200px y se adaptarán al espacio disponible.
            md: 'repeat(auto-fill, minmax(180px, 1fr))',
            lg: 'repeat(auto-fill, minmax(180px, 1fr))',
          }}
          padding="15px"
        >
          {products.map((product) => (
            <ShopProductCard key={product._id} product={product} open={open} />
          ))}
        </Box>
      )}
    </Box>
  );
}
