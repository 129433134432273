import { createSlice } from '@reduxjs/toolkit';

const productsSlice = createSlice({
  name: 'userState',
  initialState: {
    userAddress: '',
    textInfoShipping: '',
    onlyBrand: {},
    products: [],
    isLoading: false,
    openConfirm: false,
    isCreateProduct: false,
    brands: [],
    allCartProducts: [],
    allCartProductStock: [],
    promotionCode: [],
    category: [],
    subCategories: [],
    isLoadingBrands: false,
    productDescription: {},
    checkout: {
      address: '',
      activeStep: 0,
      products: [],
      subtotal: 0,
      total: 0,
      discount: 0,
      shippingBase: 0,
      shippingCurrent:0,
      payment: '',
      billing: null,
      totalItems: 0,
    },
    card: {
      cardName: '',
      cardNumber: 0,
      cardExpiryDate: 0,
      cardCode: 0,
    }
  },

  reducers: {
    setProduct: (state, action) => {
      const { option } = action.payload;
      state[option] = action.payload.value;
      return state;
    },
    setCheckoutProperty: (state, action) => {
      const { option, value } = action.payload;
      state.checkout[option] = value;
      return state;
    },
    setCardProperty: (state, action) => {
      const {option, value} = action.payload
      state.card[option] = value;
      return state;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setProduct, setCheckoutProperty, setCardProperty } = productsSlice.actions;
export default productsSlice.reducer;
