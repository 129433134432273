import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'orderState',
  initialState: {
    allOrders: [],
  },
  reducers: {
    setOrder: (state, action) => {
      const { option, value } = action.payload;
      state[option] = value;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrder } = orderSlice.actions;
