import PropTypes from 'prop-types';
import { forwardRef, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha } from '@mui/material/styles';
import { Stack, IconButton } from '@mui/material';
import Iconify from '../iconify';
import { addToCartProduct } from '../../redux/slices/products/products.thunk';

const IncrementerButton = forwardRef(
  (
    {
      idProduct,
      onIncrease,
      onDecrease,
      onExtraQuantity,
      disabledIncrease,
      disabledDecrease,
      sx,
      ...other
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const { allCartProducts } = useSelector((state) => state.products);
    const only_product = allCartProducts?.find((p) => p.product === idProduct);
    const [currentQuantity, setCurrentQuantity] = useState(only_product?.amount);
    const updateTimer = useRef(null);
    const currentQuantityRef = useRef(currentQuantity);

    useEffect(() => {
      setCurrentQuantity(only_product?.amount);
    }, [only_product?.amount, allCartProducts]);

    useEffect(() => {
      currentQuantityRef.current = currentQuantity;
    }, [currentQuantity]);

    const delayedAddToCart = (id, quantity) => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }

      updateTimer.current = setTimeout(() => {
        dispatch(addToCartProduct(id, quantity));
      }, 1000);
    };

    const handleDecrease = () => {
      if (currentQuantity > 1) {
        const newQuantity = currentQuantity - 1;
        setCurrentQuantity(newQuantity);
        delayedAddToCart(idProduct, newQuantity);
      }
    };

    const handleIncrease = () => {
      const newQuantity = currentQuantity + 1;
      setCurrentQuantity(newQuantity);
      delayedAddToCart(idProduct, newQuantity);
    };

    const handleInputChange = (e) => {
      const value = e.target.value;
      if (value === '') {
        setCurrentQuantity(null);
        return;
      }
      const intValue = parseInt(value, 10);
      if (!Number.isNaN(intValue) && intValue >= 1) {
        setCurrentQuantity(intValue);
      }
    };

    const handleInputBlur = () => {
      if (currentQuantity === null || currentQuantity < 1) {
        setCurrentQuantity(1);
        onExtraQuantity(idProduct, 1);
      } else {
        onExtraQuantity(idProduct, currentQuantity);
      }
    };

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: 0.5,
          py: 0.5,
          px: 0.75,
          width: 96,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
          justifyContent: 'center',
          ...sx,
        }}
        {...other}
      >
        <IconButton
          size="small"
          color="inherit"
          onClick={handleDecrease}
          disabled={disabledDecrease}
          sx={{ color: 'text.secondary' }}
        >
          <Iconify icon="eva:minus-fill" width={16} />
        </IconButton>

        <input
          type="number"
          value={currentQuantity || ''}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          style={{ width: '40px', textAlign: 'center', border: 'none' }}
        />

        <IconButton
          size="small"
          color="inherit"
          onClick={handleIncrease}
          disabled={disabledIncrease}
          sx={{ color: 'text.secondary' }}
        >
          <Iconify icon="eva:plus-fill" width={16} />
        </IconButton>
      </Stack>
    );
  }
);

IncrementerButton.propTypes = {
  idProduct: PropTypes.string,
  sx: PropTypes.object,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
  onExtraQuantity: PropTypes.func,
  quantity: PropTypes.number,
  stock: PropTypes.number,
  disabledDecrease: PropTypes.bool,
  disabledIncrease: PropTypes.bool,
};

export default IncrementerButton;
