import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @mui
import { Typography, Stack, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSection, StyledContent } from './styles';
import LogoFull from '../../assets/logo/LogoFull';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
  logoStatus: PropTypes.string,
  login: PropTypes.bool,
};

export default function LoginLayout({ children, illustration, title, logoStatus, login = true }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <StyledRoot
      sx={{ display: 'flex', justifyContent: 'center', overflow: { xs: 'hidden', md: 'hidden' } }}
    >
      <Logo
        sx={{
          zIndex: 9,
          position: 'fixed',
          top: 0,
          left: 0,
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
          display: { xs: 'none', md: 'block' },
        }}
      />

      <LogoMobile>
        <Link to="/">
          <LogoFull />
        </Link>
      </LogoMobile>

      <LogoTablet>
        <Link to="/">
          <LogoFull />
        </Link>
      </LogoTablet>

      <StyledSection sx={{ backgroundColor: '#00092c', overflow: 'hidden' }}>
        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/illustration_dashboard.svg'}
          sx={{
            maxWidth: 720,
            filter: 'drop-shadow(0px 0px 6px white)',
            mt: 10,
          }}
        />

        <Typography
          variant="h3"
          sx={{
            mt: 10,
            maxWidth: 700,
            textAlign: 'center',
            color: 'white',
            textShadow: '0px 0px 15px rgba(255,255,255,0.5)',
          }}
        >
          {title || 'Hola, Bienvenido de nuevo!'}
        </Typography>
      </StyledSection>

      {login ? (
        <StyledContent
          sx={{
            padding: '0px !important',
            marginLeft: { xs: 2, md: 5 },
            marginRight: { xs: 2, md: 5 },
            marginTop: { xs: -5, md: 0 },
            displayed: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '&::-webkit-scrollbar': {
              width: isMobile ? '0px' : '10px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: isMobile ? 'transparent' : '#00092c',
              borderRadius: '20px',
              backgroundClip: 'content-box',
              border: isMobile ? '0px' : '15px solid transparent',
            },
          }}
        >
          <Stack sx={{ width: 1, mt: 15 }}> {children} </Stack>
        </StyledContent>
      ) : (
        <StyledContentCustom
          sx={{
            padding: '0px !important',
            marginLeft: { xs: 3, md: 5 },
            marginRight: { xs: 3, md: 5 },
            marginTop: { xs: -5, md: -10 },
            displayed: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflowY: { xs: 'scroll', md: 'scroll' },
            height: '100%',
            '&::-webkit-scrollbar': {
              width: isMobile ? '0px' : '10px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: isMobile ? 'transparent' : '#00092c',
              borderRadius: '20px',
              height: '50%',
              backgroundClip: 'content-box',
              border: isMobile ? '0px' : '15px solid transparent',
            },
          }}
        >
          <Stack sx={{ width: 1, mt: 15 }}> {children} </Stack>
        </StyledContentCustom>
      )}
    </StyledRoot>
  );
}

const LogoMobile = styled.div`
  width: 100%;
  height: 170px;
  background-color: #00092c;
  position: absolute;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 100;
  border-radius: 0px 0px 15px 15px;

  svg {
    margin-top: -20px;
    width: 200px;
  }

  @media (max-width: 599px) {
    display: flex !important;
  }

  @media (min-width: 600px) {
    display: none !important;
  }
`;

const LogoTablet = styled.div`
  width: 100%;
  height: 200px;
  background-color: #00092c;
  position: absolute;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 100;

  & > svg {
    width: 300px;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    display: flex !important;
  }

  @media (min-width: 900px), (max-width: 599px) {
    display: none !important;
  }
`;

const StyledContentCustom = styled(StyledContent)``;
