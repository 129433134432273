import { configureStore } from '@reduxjs/toolkit';
import usersSlice from './slices/users/users.slice';
import productsSlice from './slices/products/products.slice';
import customersSlice from './slices/customers/customers.slice';
import { orderSlice } from './slices/orders/order.slices';

export default configureStore({
  reducer: {
    user: usersSlice,
    products: productsSlice,
    customers: customersSlice,
    order: orderSlice.reducer,
  },
});
