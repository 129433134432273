import { useState, memo, useEffect, useRef } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Slide,
  Popper,
  InputBase,
  InputAdornment,
  ClickAwayListener,
  Autocomplete,
  Stack,
  Typography,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
import flattenArray from '../../../utils/flattenArray';
// components
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
import SearchNotFound from '../../../components/search-not-found';
import { ShopProductList } from '../../../sections/@dashboard/e-commerce/shop';
//
import NavConfig from '../nav/config-navigation';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const StyledPopper = styled((props) => <Popper {...props} />)(({ theme }) => ({
  left: `8px !important`,
  top: `${APPBAR_MOBILE + 8}px !important`,
  width: 'calc(100% - 16px) !important',
  transform: 'none !important',
  [theme.breakpoints.up('md')]: {
    top: `${APPBAR_DESKTOP + 8}px !important`,
  },
  '& .MuiAutocomplete-paper': {
    padding: theme.spacing(1, 0),
  },
  '& .MuiListSubheader-root': {
    '&.MuiAutocomplete-groupLabel': {
      ...bgBlur({ color: theme.palette.background.neutral }),
      ...theme.typography.overline,
      top: 0,
      margin: 0,
      lineHeight: '48px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiAutocomplete-listbox': {
    '& .MuiAutocomplete-option': {
      padding: theme.spacing(0.5, 2),
      margin: 0,
      display: 'block',
      border: `dashed 1px transparent`,
      borderBottomColor: theme.palette.divider,
      '&:last-of-type': {
        borderBottomColor: 'transparent',
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
  },
}));

// ----------------------------------------------------------------------

function Searchbar() {
  const allProducts = useSelector((state) => state.products.products);

  const [open, setOpen] = useState(false);
  const [newFilterProduct, setNewFilterProduct] = useState([]);
  const [dataSearch, setDataSearch] = useState('');
  const inputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setDataSearch('');
    setNewFilterProduct([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleInputChange = (event) => {
    const search = event.target.value;
    setDataSearch(search); // Actualiza el valor del input inmediatamente

    // Cancelar el timeout anterior
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Configurar un nuevo timeout
    const newTimeout = setTimeout(() => {
      performSearch(search);
    }, 500);

    setSearchTimeout(newTimeout);
  };

  const performSearch = (search) => {
    if (search === '') {
      setNewFilterProduct([]);
      return;
    }
    const filteredProducts = allProducts.filter((product) =>
      product.productName?.toLowerCase().includes(search.toLowerCase())
    );
    setNewFilterProduct(filteredProducts);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <Stack direction="row" alignItems="center">
            <IconButtonAnimate onClick={handleOpen}>
              <Iconify icon="eva:search-fill" width="25px" height="25px" color="#F85C07" />
            </IconButtonAnimate>
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={handleOpen}
              color="#F85C07"
              fontWeight={600}
              fontSize={22}
            >
              Buscar
            </Typography>
          </Stack>
        )}
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Box>
              <InputBase
                fullWidth
                autoFocus
                ref={inputRef}
                value={dataSearch}
                onChange={handleInputChange}
                spellCheck={false}
                placeholder="Buscar Productos..."
                startAdornment={
                  !dataSearch.length > 0 ? (
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start">
                      <Iconify
                        onClick={(e) => {
                          setDataSearch('');
                          setTimeout(() => {
                            const actualInput = inputRef.current.querySelector('input');
                            if (actualInput) {
                              actualInput.focus();
                            }
                          }, 100);
                        }}
                        icon="ic:outline-close"
                        sx={{ color: '#F85C07', cursor: 'pointer' }}
                        width="25px"
                        height="25px"
                      />
                    </InputAdornment>
                  )
                }
                sx={{ height: 1, typography: 'h6' }}
              />

              {dataSearch.length > 0 && (
                <Box
                  sx={{
                    top: { sm: '80px', xs: '70px', md: '100px' },
                    width: { xs: '97x%', sm: '97%', md: '99%' },
                    padding: '10px',
                    backgroundColor: 'white',
                    position: 'fixed',
                    left: '6px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                    borderRadius: '10px',
                    maxHeight: '400px', // Aquí se define la altura máxima para 2 filas
                    overflow: 'auto', // Se permite el scroll
                  }}
                  onClick={(e) => {
                    inputRef.current.focus();
                  }}
                >
                  <ShopProductList
                    open={setOpen}
                    products={newFilterProduct}
                    loading={!allProducts.length}
                    groupByCategory={false}
                  />
                </Box>
              )}
            </Box>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

export default memo(Searchbar);

// ----------------------------------------------------------------------

function splitPath(array, key) {
  let stack = array.map((item) => ({
    path: [item.title],
    currItem: item,
  }));

  while (stack.length) {
    const { path, currItem } = stack.pop();

    if (currItem.path === key) {
      return path;
    }

    if (currItem.children?.length) {
      stack = stack.concat(
        currItem.children.map((item) => ({
          path: path.concat(item.title),
          currItem: item,
        }))
      );
    }
  }
  return null;
}

// ----------------------------------------------------------------------

function handleLoop(array, subheader = '') {
  return array?.map((list) => ({
    subheader,
    ...list,
    ...(list.children && {
      children: handleLoop(list.children, subheader),
    }),
  }));
}
