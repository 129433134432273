import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Stack } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, DialogActions } from '@mui/material';
import { RHFTextField } from '../hook-form';
import { rejectedDocument } from '../../redux/slices/customers/customers.thunk';

const RejectDocument = ({ cancel, id, typeDocument, enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      id,
      reason,
      typeDocument,
      enqueueSnackbar,
    };
    await dispatch(rejectedDocument(data));
    setIsSubmitting(false);
  };

  return (
    <Stack>
      <RHFTextField
        name="about"
        multiline
        rows={4}
        label="Descripcion del rechazo"
        onChange={(e) => setReason(e.target.value)}
        value={reason}
      />

      <DialogActions sx={{ paddingRight: '0px !important' }}>
        <Button variant="outlined" onClick={() => cancel(false)}>
          Cancelar
        </Button>
        <LoadingButton variant="contained" type="button" onClick={onSubmit} loading={isSubmitting}>
          Confirmar rechazo
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
};

RejectDocument.propTypes = {
  cancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  typeDocument: PropTypes.string.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default RejectDocument;
