import { createSlice } from '@reduxjs/toolkit';

const customersSlice = createSlice({
  name: 'customersState',
  initialState: {
    customers: [],
    isLoadingCustomers: false,
    openViewFile: false,
    dataVerified: '',
  },
  reducers: {
    setCustomers: (state, action) => {
      const { option } = action.payload;
      state[option] = action.payload.value;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCustomers } = customersSlice.actions;
export default customersSlice.reducer;
