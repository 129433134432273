import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const RHFCurrent = ({ name, label, placeholder }) => {
  const { register, watch, setValue } = useFormContext();
  const rawValue = watch(name);
  const [displayValue, setDisplayValue] = useState('');

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  };

  useEffect(() => {
    setDisplayValue(rawValue && rawValue !== '0' ? formatNumber(rawValue) : '');
  }, [rawValue]);

  const handleInputChange = (event) => {
    // Remove formatting and set raw value
    const inputRawValue = event.target.value.replace(/\./g, '');
    setValue(name, inputRawValue);
  };

  return (
    <TextField
      {...register(name)}
      name={name}
      label={label}
      placeholder={placeholder}
      type="text"
      value={displayValue}
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box component="span" sx={{ color: 'text.disabled' }}>
              $
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

RHFCurrent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default RHFCurrent;
