import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setuser } from '../redux/slices/users/users.slice';
import { setSession } from './utils';
import { getAllCartProduct, getCustomerAddress } from '../redux/slices/products/products.thunk';

const AuthLogin = ({ children }) => {
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    dispatch(setuser({ option: 'isInitialized', value: true }));
    if (userInfo) {
      dispatch(getAllCartProduct());
      dispatch(getCustomerAddress());
    }
  }, [dispatch, userInfo]);

  const initialize = useCallback(async () => {
    try {
      const user = localStorage.getItem('user');
      const token = localStorage.getItem('accessToken');

      if (user && token) {
        dispatch(setuser({ option: 'isAuthenticated', value: true }));
        setSession(token);
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return children;
};

export default AuthLogin;

AuthLogin.propTypes = {
  children: PropTypes.node, // Esto es solo un ejemplo, utiliza la validación que se ajuste a tus necesidades
};
