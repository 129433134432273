import { setSession } from '../../../auth/utils';
import axiosInstance from '../../../utils/axios';
import { setProduct } from '../products/products.slice';
import { getAllCartProduct } from '../products/products.thunk';
import { setuser } from './users.slice';

export const userLogin = (data) => async (dispatch) => {
  try {
    dispatch(setuser({ option: 'isLoading', value: true }));
    dispatch(setuser({ option: 'msgError', value: '' }));
    dispatch(setuser({ option: 'customError', value: '' }));
    const res = await axiosInstance.post('/users/login', data);
    setSession(res.data.token);
    dispatch(getAllCartProduct(res.data.user._id));
    if (res.data.user.status !== 'active') {
      dispatch(
        setuser({
          option: 'customError',
          value:
            '¡Hola! Tu cuenta se encuentra actualmente bloqueada o está en proceso de verificación 🔍. Te pedimos paciencia, ya que este proceso puede tardar hasta 48 horas ⏳. Si deseas comunicarte con nosotros, ¡haz clic! 👉 aquí.',
        })
      );
      dispatch(setuser({ option: 'isLoading', value: false }));
      return;
    }
    localStorage.setItem('user', JSON.stringify(res.data.user));
    dispatch(setuser({ option: 'user', value: res.data.user }));
    dispatch(setuser({ option: 'isAuthenticated', value: true }));
    dispatch(setuser({ option: 'isInitialized', value: true }));
    dispatch(setuser({ option: 'isLoading', value: false }));
    window.location = '/dashboard/e-commerce/shop';
  } catch (error) {
    dispatch(setuser({ option: 'msgError', value: error }));
    dispatch(setuser({ option: 'isLoading', value: false }));
    console.log(error);
  }
};

export const userRegister = (data, options) => async (dispatch) => {
  console.log(options);
  const { enqueueSnackbar } = options;

  try {
    dispatch(setuser({ option: 'isRegistered', value: true }));
    dispatch(setuser({ option: 'isUploadDocument', value: true }));
    await axiosInstance.post('/customers/register', data);
    dispatch(setuser({ option: 'isUploadDocument', value: false }));
    setTimeout(() => {
      window.location = '/login';
    }, 6000);
  } catch (error) {
    console.log(error);
    dispatch(setuser({ option: 'isRegistered', value: false }));
    dispatch(setuser({ option: 'isUploadDocument', value: false }));
    enqueueSnackbar(
      <>
        <span style={{ display: 'block' }}>
          Parece que hubo un problema al subir tus imágenes. 😓
          <br />
          Por favor, contacta a nuestro soporte e indica el código: <strong>201458</strong>.
        </span>
      </>,
      { variant: 'error', persist: true }
    );
  }
};

export const userLogout = () => async (dispatch) => {
  // Elimina el usuario del localStorage
  localStorage.removeItem('user');

  // Desautentica al usuario en Redux
  dispatch(setuser({ option: 'isAuthenticated', value: false }));
  dispatch(setuser({ option: 'user', value: {} }));
  dispatch(setProduct({ option: 'allCartProducts', value: [] }));
  dispatch(setProduct({ option: 'userAddress', value: '' }));

  // Elimina la sesión
  setSession(null);
};

export const verifyNumber = (phoneNumber) => async (dispatch) => {
  try {
    const res = await axiosInstance.post('/users/login', phoneNumber);
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};
