import { transformNumber } from '../../../utils/transformNumber';
import axiosInstance from '../../../utils/axios';
import { setCustomers } from './customers.slice';

export const getAllCustomers = () => async (dispatch) => {
  try {
    dispatch(setCustomers({ option: 'isLoadingCustomers', value: true }));
    const customers = await axiosInstance.get('/customers/all');
    dispatch(setCustomers({ option: 'customers', value: customers.data }));
    dispatch(setCustomers({ option: 'isLoadingCustomers', value: false }));
  } catch (error) {
    console.log(error);
    dispatch(setCustomers({ option: 'isLoadingCustomers', value: false }));
  }
};

export const editCustomer = (customer, options) => async (dispatch) => {
  const { reset, onClose, enqueueSnackbar } = options;
  console.log('Info actualizada =>>>>', customer);

  if (typeof customer.shipping === 'string') {
    customer.shipping = customer.shipping.replace(/\./g, '');
  }

  const number = transformNumber(customer.phoneNumber);

  customer.phoneNumber = number;

  delete customer.identificationCard;
  delete customer.camaraComercio;
  delete customer.rut;

  try {
    console.log(customer);
    const res = await axiosInstance.put('/customers/update', customer);
    reset();
    onClose();
    enqueueSnackbar('Usuario Actualizado');
    dispatch(getAllCustomers());
  } catch (error) {
    console.log(error);
  }
};

export const verifiedDocument = (data) => async (dispatch, getState) => {
  const { enqueueSnackbar, id, typeDocument } = data;

  const { customers } = getState().customers;

  let endpoint = '';

  if (typeDocument === 'identificationCard') {
    endpoint = 'ic';
  } else if (typeDocument === 'camaraComercio') {
    endpoint = 'cc';
  } else if (typeDocument === 'businessRegistration') {
    endpoint = 'br';
  }

  // Buscar el índice del cliente en lugar del cliente en sí
  const customerIndex = customers.findIndex((customer) => customer._id === id);

  // Crear una copia de los clientes
  const updatedCustomers = [...customers];

  // Crear una copia profunda del cliente que queremos modificar
  const updatedCustomer = {
    ...updatedCustomers[customerIndex],
    [typeDocument]: {
      ...updatedCustomers[customerIndex][typeDocument],
      status: 'verified', // Actualizar la propiedad 'status' aquí
    },
  };

  // Reemplazar el cliente original con la copia modificada en la lista de clientes
  updatedCustomers[customerIndex] = updatedCustomer;

  try {
    const res = await axiosInstance.post(`/verify/${endpoint}`, { id, input: 'verified' });
    dispatch(setCustomers({ option: 'customers', value: updatedCustomers }));
    enqueueSnackbar('Se verifico con exito el documento');
    dispatch(setCustomers({ option: 'openViewFile', value: false }));
    const newCustomers = await axiosInstance.get('/customers/all');
    dispatch(setCustomers({ option: 'customers', value: newCustomers.data }));
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const rejectedDocument = (data) => async (dispatch, getState) => {
  const { customers } = getState().customers;

  const { id, typeDocument, enqueueSnackbar } = data;

  let endpoint = '';

  if (typeDocument === 'identificationCard') {
    endpoint = 'ic';
  } else if (typeDocument === 'camaraComercio') {
    endpoint = 'cc';
  } else if (typeDocument === 'businessRegistration') {
    endpoint = 'br';
  }

  const info = {
    id,
    reason: data.reason,
    input: 'rejected',
  };

  // Buscar el índice del cliente en lugar del cliente en sí
  const customerIndex = customers.findIndex((customer) => customer._id === id);

  // Crear una copia de los clientes
  const updatedCustomers = [...customers];

  // Crear una copia profunda del cliente que queremos modificar
  const updatedCustomer = {
    ...updatedCustomers[customerIndex],
    [typeDocument]: {
      ...updatedCustomers[customerIndex][typeDocument],
      status: 'rejected', // Actualizar la propiedad 'status' aquí
    },
  };

  // Reemplazar el cliente original con la copia modificada en la lista de clientes
  updatedCustomers[customerIndex] = updatedCustomer;

  try {
    await axiosInstance.post(`/verify/${endpoint}`, info);
    dispatch(setCustomers({ option: 'customers', value: updatedCustomers }));
    enqueueSnackbar('se actualizo el estado del documento con exito');
    dispatch(setCustomers({ option: 'openViewFile', value: false }));
    const newCustomers = await axiosInstance.get('/customers/all');
    dispatch(setCustomers({ option: 'customers', value: newCustomers.data }));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
