import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import { MuiTelInput } from 'mui-tel-input';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import axios from 'axios';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { InputAdornment, Typography } from '@mui/material';
import { Stack } from '@mui/system';
// _mock
// assets
import { countries } from '../../../assets/data';
// components
import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFCurrent,
} from '../../../components/hook-form';
import directions from '../../../utils/directions.json';
import { setCustomers } from '../../../redux/slices/customers/customers.slice';
import RejectDocument from '../../../components/view-document/RejectDocument';
import { verifiedDocument } from '../../../redux/slices/customers/customers.thunk';

export default function ViewFile() {
  const { enqueueSnackbar } = useSnackbar();
  const [valueShipping, setValueShipping] = useState('');
  const [onClose] = useState(false);
  const [objectURL, setObjectURL] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { openViewFile, dataVerified, info } = useSelector((state) => state.customers);

  console.log(dataVerified);

  const methods = useForm({});

  const { reset, watch, control, handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);
    try {
      const infoVerified = {
        id: dataVerified.info._id,
        typeDocument: dataVerified.id,
        enqueueSnackbar,
      };
      await new Promise((resolve) => setTimeout(resolve, 500));

      const res = await dispatch(verifiedDocument(infoVerified));
      setIsSubmitting(res);
    } catch (error) {
      console.error(error);
    }
  });

  const handleMouseOver = () => {
    setIsZoomed(true);
  };

  const handleMouseOut = () => {
    setIsZoomed(false);
  };

  const reject = () => {
    console.log('reject');
    setIsRejected(true);
  };

  const isPDF = decodeURIComponent(dataVerified.file).toLowerCase().includes('.pdf');

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={openViewFile}
      PaperProps={{
        sx: { maxWidth: 920 },
      }}
    >
      <FormProvider methods={methods}>
        <DialogTitle>Verificacion</DialogTitle>

        <DialogContent>
          {isRejected ? (
            <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
              Escribe el motivo por el cual rechazas el documento{' '}
              <strong>{dataVerified.text}.</strong>
            </Alert>
          ) : (
            <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
              Por favor, verifica que la información del cliente sea correcta. Vamos a revisar{' '}
              <strong>{dataVerified.text}.</strong>
            </Alert>
          )}

          {isRejected ? (
            <RejectDocument
              cancel={setIsRejected}
              id={dataVerified.info._id}
              typeDocument={dataVerified.id}
              enqueueSnackbar={enqueueSnackbar}
            />
          ) : (
            <>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
                justifyItems="center"
                alignItems={{ xs: 'center', sm: 'flex-start' }}
              >
                {isPDF ? (
                  <Button variant="contained" color="primary">
                    <a
                      href={dataVerified.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Ver archivo
                    </a>
                  </Button>
                ) : (
                  <TransformWrapper defaultScale={1} defaultPositionX={200} defaultPositionY={100}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <Stack spacing={1}>
                        <Stack direction="row" spacing={1}>
                          <Button
                            sx={{ width: '30px', height: '25px', fontSize: '12px' }}
                            type="button"
                            variant="contained"
                            onClick={() => {
                              zoomIn();
                            }}
                          >
                            Acercar
                          </Button>

                          <Button
                            sx={{ width: '30px', height: '25px', fontSize: '12px' }}
                            type="button"
                            variant="contained"
                            onClick={() => {
                              zoomOut();
                            }}
                          >
                            Alejar
                          </Button>

                          <Button
                            sx={{ height: '25px', fontSize: '12px' }}
                            type="button"
                            variant="contained"
                            onClick={() => {
                              console.log('Resetting transform...');
                              resetTransform();
                            }}
                          >
                            Vista Original
                          </Button>
                        </Stack>
                        <TransformComponent
                          contentStyle={{ borderRadius: '10px', overflow: 'hidden' }}
                        >
                          <img
                            src={dataVerified.file}
                            alt="documento"
                            style={{ width: '300px', height: '350px' }}
                          />
                        </TransformComponent>
                      </Stack>
                    )}
                  </TransformWrapper>
                )}
                <Box width="80%">
                  <Typography variant="subtitle1" sx={{ mt: 3, width: '100%' }}>
                    Información del cliente
                  </Typography>
                  <Stack spacing={2} sx={{ mt: 3, width: '100%' }}>
                    <Alert
                      severity="warning"
                      variant="outlined"
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center', // Alineación vertical central
                        overflow: 'hidden', // Oculta el desbordamiento
                        '& .MuiAlert-message': {
                          fontSize: '12px',
                          lineHeight: '1.3', // Ajusta el interlineado
                          whiteSpace: 'nowrap', // Evita que el texto se envuelva al siguiente renglón
                          overflow: 'hidden', // Oculta el desbordamiento de texto
                          textOverflow: 'ellipsis', // Agrega puntos suspensivos si el texto es demasiado largo
                          alignItems: 'center', // Alineación vertical central
                        },
                        '& .MuiAlert-icon': {
                          fontSize: '18px', // Ajusta el tamaño del contenedor del ícono
                          alignItems: 'center', // Alineación vertical centrala
                          '& .MuiSvgIcon-root': {
                            fontSize: '18px', // Ajusta el tamaño del SVG
                          },
                        },
                      }}
                    >
                      Verifica el numero del documento
                    </Alert>
                    <RHFTextField
                      name="name"
                      label="Numero de cedula"
                      disabled
                      defaultValue={dataVerified?.info?.cc}
                    />
                    <Alert
                      severity="warning"
                      variant="outlined"
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center', // Alineación vertical central
                        overflow: 'hidden', // Oculta el desbordamiento
                        '& .MuiAlert-message': {
                          fontSize: '12px',
                          lineHeight: '1.3', // Ajusta el interlineado
                          whiteSpace: 'nowrap', // Evita que el texto se envuelva al siguiente renglón
                          overflow: 'hidden', // Oculta el desbordamiento de texto
                          textOverflow: 'ellipsis', // Agrega puntos suspensivos si el texto es demasiado largo
                          alignItems: 'center', // Alineación vertical central
                        },
                        '& .MuiAlert-icon': {
                          fontSize: '18px', // Ajusta el tamaño del contenedor del ícono
                          alignItems: 'center', // Alineación vertical central
                          '& .MuiSvgIcon-root': {
                            fontSize: '18px', // Ajusta el tamaño del SVG
                          },
                        },
                      }}
                    >
                      Verifica los nombres del cliente
                    </Alert>

                    <RHFTextField
                      name="name"
                      label="Nombres"
                      disabled
                      defaultValue={`${dataVerified.info?.firstName} ${dataVerified.info?.lastName}`}
                    />
                  </Stack>
                </Box>
              </Box>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(setCustomers({ option: 'openViewFile', value: false }));
                  }}
                >
                  Cancelar
                </Button>

                <LoadingButton
                  sx={{ backgroundColor: 'red' }}
                  type="button"
                  variant="contained"
                  onClick={reject}
                >
                  Rechazar
                </LoadingButton>

                <LoadingButton
                  sx={{ backgroundColor: 'green' }}
                  type="button"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={onSubmit}
                >
                  Aceptar
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
}
