import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import { MuiTelInput } from 'mui-tel-input';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { InputAdornment, Typography } from '@mui/material';
import { Stack } from '@mui/system';
// _mock
// assets
import { countries } from '../assets/data';
// components
import Iconify from '../components/iconify';
import { useSnackbar } from '../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFCurrent,
  RHFCheckbox,
} from '../components/hook-form';
import directions from '../utils/directions.json';
import ViewFile from '../pages/dashboard/customers/ViewFile';
import { setCustomers } from '../redux/slices/customers/customers.slice';
import { editCustomer } from '../redux/slices/customers/customers.thunk';

// ----------------------------------------------------------------------

const USER_STATUS_OPTIONS = [
  { value: 'active', label: 'Activo' },
  { value: 'pending', label: 'Pendiente' },
  { value: 'banned', label: 'Bloqueado' },
  { value: 'rejected', label: 'Rechazado' },
];

const PERSON_OPTIONS = [
  { value: 'natural', label: 'Natural' },
  { value: 'juridica', label: 'Juridica' },
];

const ComponentViewDocument = ({ text, icon, status, data, viewDocument, file, id }) => {
  return (
    <BoxCustom status={status} onClick={() => viewDocument(text, file, data, id)}>
      <Iconify icon={icon} width="30px" height="100%" />
      <p>{text}</p>
    </BoxCustom>
  );
};

const ComponentVerifiedDocument = () => {
  console.log('test');
};

export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [valueShipping, setValueShipping] = useState('');
  const [isViewDocument, setIsViewDocument] = useState(false);
  const [textDocument, setTextDocument] = useState('');
  const [options, setOptions] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { openViewFile } = useSelector((state) => state.customers);

  const NewUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Nombre es requerido'),
    lastName: Yup.string().required('Apellido es requerido'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    company: Yup.string().required('Company is required'),
    department: Yup.string().required('State is required'),
    city: Yup.string().required('La ciudad es requerida'),
  });

  const defaultValues = useMemo(
    () => ({
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      email: currentUser?.email || '',
      phoneNumber: currentUser?.phoneNumber || '',
      address: currentUser?.address || '',
      department: currentUser?.department || '',
      city: currentUser?.city || '',
      status: currentUser?.status,
      company: currentUser?.companyName || '',
      companyType: currentUser?.companyType || '',
      cc: currentUser?.cc || '',
      nit: currentUser?.nit || '',
      shipping: currentUser?.shipping || '',
      isAbleToRecieveDelivery: currentUser?.isAbleToRecieveDelivery || false,
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { reset, watch, control, handleSubmit } = methods;

  const departamento = watch('department');

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);
    const dataOriginal = { ...data };

    dataOriginal.id = currentUser._id;

    const optionsQuick = {
      reset,
      onClose,
      enqueueSnackbar,
    };

    try {
      await dispatch(editCustomer(dataOriginal, optionsQuick));
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  });

  const viewDocument = (text, file, info, id) => {
    if (!shipping && !currentUser?.isAbleToRecieveDelivery) {
      return enqueueSnackbar(
        <div>
          No puedes verificar los documentos de este usuario sin haber:
          <br />
          - Especificado el valor de envío 🚚
          <br />
          -En el caso contrario, que no se puede ofrecer entrega a domicilio 🏠
        </div>,
        {
          variant: 'error',
          persist: true,
        }
      );
    }

    const data = {
      text,
      file,
      info,
      id,
    };
    dispatch(setCustomers({ option: 'openViewFile', value: true }));
    return dispatch(setCustomers({ option: 'dataVerified', value: data }));
  };

  const handleChange = (event) => {
    const value = event.target.value;

    setValueShipping(
      parseFloat(value).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })
    );
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
  };

  const messageStateAccount = () => {
    if (currentUser?.status === 'pending') {
      return (
        <Alert variant="outlined" severity="info" sx={{ mb: 3 }}>
          La cuenta está esperando confirmación
        </Alert>
      );
    } else if (currentUser?.status === 'active') {
      return (
        <Alert variant="outlined" severity="success" sx={{ mb: 3 }}>
          La cuenta está verificada
        </Alert>
      );
    } else if (currentUser?.status === 'rejected') {
      return (
        <Alert variant="outlined" severity="error" sx={{ mb: 3 }}>
          La cuenta está rechazada
        </Alert>
      );
    } else {
      return (
        <Alert variant="outlined" severity="warning" sx={{ mb: 3 }}>
          La cuenta está bloqueada
        </Alert>
      );
    }
  };

  const shipping = watch('shipping');
  const formattedShipping = formatCurrency(shipping);

  const renderCitys = (state) => {
    const citys = directions.find((option) => option.departamento === departamento);

    return (
      <RHFSelect name="city" label="Ciudad">
        {citys?.ciudades?.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </RHFSelect>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 820 },
      }}
    >
      {openViewFile && <ViewFile options={options} />}
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Edición</DialogTitle>

        <DialogContent>
          {messageStateAccount()}

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
            }}
          >
            <RHFSelect name="status" label="Verificacion">
              {USER_STATUS_OPTIONS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <RHFSelect name="companyType" label="Tipo Persona" onChange={handleChange}>
              {PERSON_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFTextField name="cc" label="Cedula" />
            <RHFTextField name="nit" label="Nit" />
            <RHFTextField name="firstName" label="Nombres" />
            <RHFTextField name="lastName" label="Apellidos" />
            <RHFTextField name="email" label="Correo Electronico" />
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MuiTelInput
                  {...field}
                  label="Numero de telefono"
                  onlyCountries={['CO', 'ES']}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <RHFTextField name="company" label="Compañia" />
            <RHFSelect name="department" label="Departamento">
              {directions?.map((option) => (
                <MenuItem key={option.id} value={option.departamento}>
                  {option.departamento}
                </MenuItem>
              ))}
            </RHFSelect>
            {departamento && renderCitys(departamento)}
            <RHFTextField name="address" label="Direccion" />
            <RHFCurrent name="shipping" label="Valor de envio" placeholder="0.00" />
            <RHFCheckbox name="isAbleToRecieveDelivery" label="No se permite domicilio" />
          </Box>
        </DialogContent>
        <Stack sx={{ p: '0px 24px', mt: 2 }} spacing={1}>
          <Typography variant="subtitle2">Documentacion legal</Typography>
          <Box display="flex" gap="15px">
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#ffab00',
                  border: 'none',
                }}
              />
              <p style={{ margin: '0px', fontSize: '12px' }}>Pendiente</p>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#1D816C',
                  border: 'none',
                }}
              />
              <p style={{ margin: '0px', fontSize: '12px' }}>Verificado</p>
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap="10px">
              <Box
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#C9504B',
                  border: 'none',
                }}
              />
              <p style={{ margin: '0px', fontSize: '12px' }}>Rechazado</p>
            </Stack>
          </Box>
          <Box display="flex" flexDirection="row" gap="15px">
            <ComponentViewDocument
              text="Identificacion Personal"
              icon="solar:user-id-line-duotone"
              id="identificationCard"
              status={currentUser?.identificationCard.status}
              file={currentUser?.identificationCard.url}
              data={currentUser}
              viewDocument={viewDocument}
            />
            <ComponentViewDocument
              text="Camara Comercio"
              icon="solar:document-line-duotone"
              id="camaraComercio"
              status={currentUser?.camaraComercio.status}
              file={currentUser?.camaraComercio.url}
              data={currentUser}
              viewDocument={viewDocument}
            />
            <ComponentViewDocument
              text="RUT"
              icon="solar:document-line-duotone"
              id="businessRegistration"
              status={currentUser?.businessRegistration.status}
              file={currentUser?.businessRegistration.url}
              data={currentUser}
              viewDocument={viewDocument}
            />
          </Box>
        </Stack>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Actualizar
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

ComponentViewDocument.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  status: PropTypes.string,
  viewDocument: PropTypes.func,
  file: PropTypes.string,
  data: PropTypes.object,
  id: PropTypes.string,
};

const statusToColorMap = {
  pending: {
    background: '#f7ead0',
    text: '#B76E00',
    svg: 'color7',
  },
  verified: {
    background: '#e1f9d7',
    text: '#34b700',
    svg: 'color8',
  },
  rejected: {
    background: '#ffbbbb',
    text: '#d55a5a',
    svg: 'color9',
  },
};

const BoxCustom = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => statusToColorMap[props.status].background};
  color: ${(props) => statusToColorMap[props.status].text};
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:hover {
    border: ${(props) => `1px solid ${statusToColorMap[props.status].text}`};
  }

  p {
    display: flex;
    align-items: center;
    height: 30%;
    text-align: center;
    font-size: 10px;
    margin: 0;
    line-height: 1.2;
  }
`;
