import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Stack,
  Fab,
  TextField,
  FormControl,
  Typography,
  Tooltip,
} from '@mui/material';

import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState, useEffect, useRef } from 'react';
import {
  UpdateExtraAddtoCartProduct,
  UpdateaddToCartProduct,
  addToCartProduct,
  deleteCartProduct,
  getAllCartProduct,
  getProduct,
} from '../../../../redux/slices/products/products.thunk';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { fCurrency } from '../../../../utils/formatNumber';
import { useDispatch } from '../../../../redux/store';
import Image from '../../../../components/image';

ShopProductCard.propTypes = {
  product: PropTypes.object,
  open: PropTypes.func,
};

export default function ShopProductCard({ product, open }) {
  const dispatch = useDispatch();
  const { allCartProducts } = useSelector((state) => state.products) ?? {};

  const getProductAmount = (productId) => {
    const productInCart = allCartProducts?.find((p) => p.product === productId);
    return productInCart ? productInCart.amount : 0;
  };

  const outerRef = useRef(null);
  const { _id, productName, img, price, priceSale, brand, stock } = product;
  const linkTo = PATH_DASHBOARD.eCommerce.view(paramCase(_id));
  const [showOptions, setShowOptions] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [priceE, setPriceE] = useState(0);
  const [quantity, setQuantity] = useState(getProductAmount(_id));
  const [selected, setSelected] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [first, setfirst] = useState(1);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleaddProduct = (action, event = null) => {
    if (action === 'add') {
      setButtonClicked(true);
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;

        // Si es el primer producto
        if (first === 1) {
          dispatch(addToCartProduct(_id, newQuantity));
          return newQuantity;
        }
        // Para los productos posteriores
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
          dispatch(addToCartProduct(_id, newQuantity));
        }, 3000);

        setTimeoutId(newTimeoutId);
        return newQuantity;
      });

      handleAdd();
    } else if (action === 'del') {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity > 0 ? prevQuantity - 1 : 0;
        setPriceE(originalPrice * newQuantity);

        if (newQuantity === 0) {
          // Si la nueva cantidad es 0, hacemos un dispatch directo para eliminarlo
          dispatch(deleteCartProduct(_id));
        } else {
          // Si no es 0, entonces esperamos 3 segundos antes de actualizar
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          const newTimeoutId = setTimeout(() => {
            dispatch(addToCartProduct(_id, newQuantity));
          }, 1000);

          setTimeoutId(newTimeoutId);
        }

        return newQuantity;
      });

      if (quantity <= 1) {
        setShowOptions(false);
      }
    } else if (action === 'change') {
      const newQuantity = parseInt(event.target.value, 10);
      const updatedQuantity = Number.isNaN(newQuantity) ? 0 : Math.max(newQuantity, 0);
      setQuantity(updatedQuantity);
      setPriceE(originalPrice * updatedQuantity);

      if (updatedQuantity > 3) {
        setShowOptions(false);
      } else if (!showOptions) {
        setShowOptions(true);
        setOriginalPrice(price);
        setSelected(true);
        setPriceE(price);
      } else if (selected) {
        setPriceE(originalPrice * updatedQuantity);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        dispatch(addToCartProduct(_id, updatedQuantity));
      }, 3000);

      setTimeoutId(newTimeoutId);
    } else if (action === 'actionButton') {
      dispatch(addToCartProduct(_id, event));
    }
  };

  const handleAdd = () => {
    setfirst(0);

    if (quantity > 3) {
      setShowOptions(false);
    }

    if (quantity + 1 <= 3 && !showOptions) {
      setShowOptions(true);
      setOriginalPrice(price);
      setSelected(true);
      setPriceE(price);
    } else if (selected) {
      setPriceE(originalPrice * (quantity + 1));
    }
  };

  const handleOptionChange = (value) => {
    const unitValue = Number(value);
    setOriginalPrice(price);
    setSelected(true);
    setShowOptions(false);
    setQuantity(unitValue);
    setPriceE(price * unitValue);
    handleaddProduct('actionButton', unitValue);
  };

  const handleDescription = (ProductID) => {
    dispatch(getProduct(ProductID));
  };

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  useOnClickOutside(outerRef, () => {
    setfirst(1);
    setShowOptions(false);
    setSelected(false);
    setButtonClicked(false);
  });

  return (
    <Card
      ref={outerRef}
      sx={{
        width: '180px',
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'absolute',
            zIndex: 25,
          }}
        >
          <Stack
            sx={{
              width: first === 0 ? '80%' : '%100',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '10px',
              backgroundColor: first === 0 ? '#f85c07' : 'transparent',
              borderRadius: '8px',
              overflow: 'hidden',
              p: 0.5,
            }}
          >
            {first === 0 ? (
              <>
                <Fab
                  sx={{
                    backgroundColor: '#f85c07',
                    height: '36px',
                    width: '36px',
                    minWidth: 'initial',
                    boxShadow: 'none',
                  }}
                  aria-label="remove"
                  size="small"
                  onClick={() => {
                    handleaddProduct('del');
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </Fab>

                <TextField
                  id="filled-basic"
                  variant="filled"
                  size="small"
                  sx={{
                    width: '50%',
                    height: '36px',
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                    '& .MuiFilledInput-input': {
                      color: 'white',
                      padding: '10px 12px',
                      textAlign: 'center',
                    },
                    '& .MuiFilledInput-root': {
                      backgroundColor: '#f85c07',
                      borderRadius: '4px',
                    },
                  }}
                  value={quantity}
                  onChange={(e) => {
                    handleaddProduct('change', e);
                  }}
                />
              </>
            ) : null}
            {stock > 0 ? (
              <Fab
                sx={{
                  backgroundColor: getProductAmount(_id) > 0 ? '#212b36' : '#f85c07',
                  boxShadow: 'none',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                size="small"
                aria-label="add"
                onClick={() => {
                  handleaddProduct('add');
                }}
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  buttonClicked ? (
                    <AddIcon />
                  ) : getProductAmount(_id) > 0 ? (
                    <Typography
                      variant="body2"
                      color="white"
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        letterSpacing: '0.05em',
                      }}
                    >
                      {getProductAmount(_id)}
                    </Typography>
                  ) : (
                    <AddIcon />
                  )
                  /* eslint-enable no-nested-ternary */
                }
              </Fab>
            ) : (
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#ffff0061',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  pb: 0.5,
                  color: 'black',
                  borderRadius: '5px',
                }}
              >
                Agotado
              </Box>
            )}
          </Stack>

          {showOptions && (
            <Stack sx={{ position: 'absolute', top: '60px', zIndex: '10' }}>
              <FormControl>
                <Stack
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '144px',
                  }}
                >
                  <Box
                    onClick={() => handleOptionChange('6')}
                    sx={{
                      backgroundColor: '#ffa500e8',
                      borderRadius: '50%',
                      boxShadow: 5,
                      color: 'white',
                      width: '38px',
                      height: '38px',
                      fontSize: '0.7rem',
                      padding: '0',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    6<Box sx={{ fontSize: '0.5rem', textAlign: 'center' }}>und</Box>
                  </Box>
                  <Box
                    onClick={() => handleOptionChange('12')}
                    sx={{
                      backgroundColor: '#ffa500e8',
                      borderRadius: '50%',
                      boxShadow: 5,
                      color: 'white',
                      width: '38px',
                      height: '38px',
                      fontSize: '0.7rem',
                      padding: '0',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    12
                    <Box sx={{ fontSize: '0.5rem', textAlign: 'center' }}>und</Box>
                  </Box>
                  <Box
                    onClick={() => handleOptionChange('24')}
                    sx={{
                      backgroundColor: '#ffa500e8',
                      borderRadius: '50%',
                      boxShadow: 5,
                      color: 'white',
                      width: '38px',
                      height: '38px',
                      fontSize: '0.7rem',
                      padding: '0',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    24
                    <Box sx={{ fontSize: '0.5rem', textAlign: 'center' }}>und</Box>
                  </Box>
                </Stack>
              </FormControl>
            </Stack>
          )}
        </Stack>
        <Card
          sx={{
            '&:hover .add-cart-btn': {
              opacity: 1,
            },
            position: 'relative',
          }}
          onClick={() => (open ? open(false) : null)}
        >
          <Link
            component={RouterLink}
            onClick={() => open(false)}
            to={linkTo}
            color="inherit"
            variant="subtitle2"
            onClick={() => {
              handleDescription(_id);
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.45)',
                borderRadius: '16px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                backdropFilter: showOptions ? 'blur(6px)' : 'none',
                WebkitBackdropFilter: showOptions ? 'blur(6px)' : 'none',
                opacity: showOptions ? 1 : 0,
                transition: 'backdropFilter 1s ease-in-out, opacity 1s ease-in-out',
                zIndex: 1,
              }}
            />
            <Image
              alt={productName}
              src={img}
              onClick={() => open(false)}
              ratio="1/1"
              sx={{
                borderRadius: 1.5,
                filter: showOptions ? 'blur(1px)' : 'none',
                transition: 'filter 1s ease-in-out',
              }}
            />
          </Link>
        </Card>
      </Box>
      <Stack spacing={0.3} sx={{ p: 1 }}>
        <Typography sx={{ fontSize: '12px', color: 'gray', fontWeight: 400 }}>
          {brand?.name}
        </Typography>

        <Tooltip title={productName}>
          <Link
            component={RouterLink}
            to={linkTo}
            color="inherit"
            sx={{ fontSize: '14px', fontWeight: 500 }}
            noWrap
            onClick={() => {
              handleDescription(_id);
            }}
          >
            {productName}
          </Link>
        </Tooltip>

        <Stack direction="row" spacing={0.5} sx={{ typography: 'subtitle1' }}>
          {priceSale > 0 && (
            <Box component="span" sx={{ color: 'text.disabled', textDecoration: 'line-through' }}>
              {priceSale}
            </Box>
          )}
          <Box component="span" sx={{ fontSize: '15px', fontWeight: '700' }}>
            {fCurrency(price)}
          </Box>
        </Stack>

        {/* Total de los productos */}
        {priceE > 0 && (
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#ff5722c2;',
              fontSize: '14px',
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              pt: 0.5,
              pb: 0.5,
              color: 'white',
              borderRadius: '5px',
            }}
          >
            Total: {fCurrency(priceE)}
          </Box>
        )}
      </Stack>
    </Card>
  );
}
